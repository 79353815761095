@import './base.css';

.carousel-container{
    /* border: solid; */
    position: relative;
    overflow: hidden;
    margin-top: -16px;
}

.navigation-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    color: rgb(235, 235, 235);
}
.navigation-btn.left{
    left: 10px;
}
.navigation-btn.right{
    right:10px;
}

.inner{
    white-space: nowrap;
    transition: transform 0.3s linear;
}

.carousel-main{
    width: 100%;
    height: 30rem;
    background-position: center;
    background-size: cover;
    display: inline-flex;
    align-items: center;
    justify-content: center;

}

.carousel-main.sm{
    height: 15rem;
}

@media (max-width: 600px){
    .carousel-main{
        height: 15rem;
    }
}