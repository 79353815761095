:root {
    --bg: #f0f0f0;
    --text-clr: #222222;
    --text-clr-sec: #696c72;
    --bg-light: #fff;
    --bg-sec: #65748c;
    --bg-dark: #2b2c2e;
    --hover-bg-dark: #393a3b;
    --hover-bg-sec: #5b6980;
    --bg-green-status: #23bd51;
    --bg-orange-status: #dfa60b;
    --bg-red-status: #da2f2f;


    --fs-1: 2rem;
    --fs-2: 1.1rem;
    --fs-3: 1rem;
    --fs-auction-1: 1.5rem;
    --fs-auction-2: 1.3rem;
    --fs-auction-3: 1rem;

    --card-ht: 15rem;
    --bid-card-ht: 12rem;
    --header-ht: 4.3rem;
    --footer-ht: 6rem;
}

* {
    box-sizing: border-box !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg) !important;
    padding-bottom: var(--footer-ht);
    max-width: 100vw;
    width: 100vw;
    overflow-x: hidden;
    /* padding-top: 1rem; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.MuiContainer-root {
    padding: 0 !important;
}

.root {}




/* ==========  UTILITY  ============== */
.text-center {
    text-align: center !important;
    width: 100%;
    font-weight: 700 !important;
  }
  
  .msg{
    font-size: var(--fs-1);
  }
  .msg.danger{
    color: var(--bg-red-status);
  }
  .msg.success{
    color: var(--bg-green-status);
  }
  
  .link {
    color: rgb(86, 29, 240);
    text-decoration: underline;
    cursor: pointer;
    transition: all 100ms linear;
  }
  
  .link:hover {
    color: rgb(90, 72, 139);
  }
  
  .section-title {
    font-size: var(--fs-1) !important;
    text-align: center;
  }
  
  .error-msg {
    color: red;
    padding: 0 5vw;
    font-size: var(--fs-3);
    /* width: 100%; */
  }
  
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem !important;
    height: 2.5rem !important;
    border: none !important;
    border-radius: 10px;
    margin-top: 1rem !important;
    cursor: pointer;
    transition: all 150ms linear;
    position: relative;
    margin-right: 2rem;
    text-decoration: none;
  }
  
  .btn:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  
  .danger-btn {
    background-color: rgb(223, 23, 23);
    color: var(--bg-light);
  }
  
  .danger-btn:hover {
    background-color: rgb(238, 31, 31);
  }
  
  .primary-btn {
    background-color: var(--bg-dark) !important;
    color: var(--bg-light) !important;
  }
  
  .primary-btn:hover {
    background-color: var(--hover-bg-dark) !important;
  }
  
  .primary-btn:disabled {
    opacity: 0.7;
  }
  
  .light-btn {
    background-color: var(--bg-sec) !important;
    color: var(--bg-light) !important;
  }
  
  .light-btn:hover {
    background-color: var(--hover-bg-sec) !important;
  }
  
  .light-btn-ghost {
    background-color: transparent !important;
    color: var(--bg-sec) !important;
    border: 1px solid var(--bg-sec) !important;
    transition: all 150ms linear;
  }
  
  .light-btn-ghost:hover {
    background-color: var(--bg-sec) !important;
    color: var(--bg-light) !important;
  }
  
  .d-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .btn-container {
    /* gap: 2rem; */
    display: flex;
  }
  
  .btn input {
    border: solid greenyellow;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .btn-pill {
    border-radius: 20px;
  }
  
  .tab-container {
    position: relative;
    background-color: transparent !important;
  }
  
  .tabs {
    width: auto;
    position: absolute;
    right: 0;
    top: -5.8rem;
    width: 40%;
    border: solid;
    border-radius: 40px;
    padding: 4px;
    border: 0.6px solid rgba(34, 34, 34, 0.541) !important;
  }
  
  .MuiDataGrid-toolbarContainer {
    position: absolute;
    top: -3.6rem;
    right: 0;
    width: 8rem;
    border: 0.6px solid rgba(34, 34, 34, 0.541) !important;
    border-radius: 20px;
    transition: all 150ms linear;
  }
  
  .MuiDataGrid-toolbarContainer:hover {
    border-color: var(--bg-sec) !important;
    background-color: var(--bg-sec) !important;
  }
  
  .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    color: var(--bg-sec) !important;
  }
  
  .css-1knaqv7-MuiButtonBase-root-MuiButton-root:hover {
    color: white !important;
  }
  
  .MuiDataGrid-toolbarContainer span {
    display: none;
  }
  
  .tab {
    width: 50% !important;
    border: none !important;
    outline: none !important;
    border-radius: 40px !important;
    color: var(--bg-sec) !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
  }
  
  .tab.Mui-selected {
    background-color: var(--bg-sec) !important;
    color: var(--bg-light) !important;
  }
  
  .MuiTabs-indicator {
    display: none;
  }
  
  .MuiPaper-root {
    /* background-color: transparent !important; */
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
  
  .MuiDataGrid-root {
    border: none !important;
    box-shadow: none !important;
  }
  
  .MuiDataGrid-main {
    background-color: var(--bg-light) !important;
    border-radius: 10px !important;
    box-shadow: none !important;
  }
  
  .MuiDataGrid-columnHeaders {
    background-color: var(--bg-sec) !important;
    color: var(--bg-light) !important;
    font-weight: 600 !important;
  }
  
  .header-right-aligned {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .header-right-aligned button {
    margin: 0 !important;
  }
  
  .header-flexed {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .btn-top-right {
    position: absolute !important;
    top: 2rem;
    right: 1rem;
  }
  .file-upload-btn{
    margin-bottom: 1rem !important;
    
  }
  
  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    position: relative;
  }
  
  .flex-column {
    align-items: flex-start;
  
  }
  
  .back-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    position: absolute;
    top: 3.4rem;
    left: 2.4rem;
    border: 1px solid var(--bg-sec);
    color: var(--bg-sec);
    padding: 0.3rem .5rem;
    border-radius: 20px;
    background-color: var(--bg);
    padding: .35rem 2rem;
  }
  
  .back-btn:hover {
    color: var(--bg-dark);
    border-color: var(--bg-dark);
  }
  
  .back-btn:hover svg {
    transform: translateX(-2px);
  }
  
  .back-btn svg {
    width: 20px;
  }
  
  .back-btn-2{
    margin-top: .5rem ;
    /* margin-left: 5vw; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #02020270;
  }
  






@media only screen and (max-width: 600px) {
    :root {
        --fs-1: 23px;
        --fs-2: 18px;
        --fs-3: 15px;
        --fs-4: 13px;
        --fs-5: 12px;
        --fs-6: 11px;

        --fs-auction-1: 15px;
        --fs-auction-2: 15px;
        --fs-auction-3: 15px;

        --card-ht: 10rem
    }
}